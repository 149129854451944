<template>
  <a-modal
    centered
    destroyOnClose
    :zIndex="zIndex"
    :title="modealTitle"
    :closable="closable"
    :width="formMode ? (width === 520 ? 'auto' : width) : width"
    :maskClosable="maskClosable"
    :bodyStyle="{ padding: '24px', background }"
    :maskStyle="{ background: 'rgba(0,0,0,0.4)' }"
  >
    <div
      v-if="!tooSmall"
      :class="{ 'form-mode': formMode }"
      class="scroll-bar-design"
      style="max-height: calc(100vh - 210px)"
    >
      <slot></slot>
    </div>
    <div v-else :class="{ 'form-mode': formMode }">
      <slot></slot>
    </div>
    <template #footer>
      <a-button key="back" class="MyCancel"  @click="handleCancel" v-if="!noCancel">{{ cancelText }}</a-button>
      <a-button key="submit" class="MySubmit" :type="okType" :loading="confirmLoading" @click="handleOk">{{ okText }}</a-button>
    </template>
  </a-modal>
</template>

<script>
/*
  弹窗里如果放下拉框或者日期选择框，要加个 dropdownClassName="z-index-1250"
*/
export default {
  name: 'WrapModal',
  props: {
    // 输入框少于三个，并且有下拉框、时间选择框等，传true （下拉框不跟随弹窗内容滚动）
    tooSmall: {
      type: Boolean,
      default: false
    },
    modealTitle: {
      type: String,
      default: ''
    },
    // modal 的body背景色
    background: {
      type: String,
      default: ''
    },
    // 弹窗最外层宽度
    width: {
      type: [String, Number],
      default: 520
    },
    // 能否点击遮罩关闭
    maskClosable: {
      type: Boolean,
      default: false
    },
    // 确定按钮加载状态
    confirmLoading: {
      type: Boolean,
      default: false
    },
    // 表单模式 控制右侧宽度固定400，label宽度auto，表单label居右
    // 如果未传width，宽度auto
    formMode: {
      type: Boolean,
      default: false
    },
    // 不显示取消按钮
    noCancel: {
      type: Boolean,
      default: false
    },
    // 确定按钮文字
    okText: {
      type: String,
      default: '确定'
    },
    // 确定按钮类型
    okType: {
      type: String,
      default: 'primary'
    },
    // 取消按钮文字
    cancelText: {
      type: String,
      default: '取消'
    },
    zIndex: {
      type: Number,
      default: 1000
    },
    closable: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = ''
        }
      },
      immediate: true
    }
  },
  methods: {
    handleOk() {
      this.$emit('ok')
    },
    handleCancel() {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="less" scoped>
.form-mode {
  padding: 2px;
  :deep(.ant-form-item) {
    justify-content: flex-end;
  }
  :deep(.ant-form-item-label) {
    line-height: 32px;
  }
  :deep(.ant-form-item-control) {
    margin-left: 8px;
    width: 400px;
    line-height: 32px;
    flex: none;
  }
  
  :deep(.ant-calendar-picker),
  :deep(.ant-time-picker) {
    width: 100%;
  }
}
// 滚动条与内容间距
:deep(.ant-modal-body) .scroll-bar-design {
  padding-right: 5px;
}

:deep(.ant-btn) {
  border-radius: 2px;
}

:deep(.ant-form-item:nth-last-of-type(1)) {
  margin-bottom: 0;
}
// 滚动条设计样式 （可能会有浏览器兼容问题，可以去掉）
.scroll-bar-design {
  @scrollBarSize: 6px;
  // max-height: calc(100% - 202px);
  overflow-y: scroll;
  /* 定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  &::-webkit-scrollbar {
    width: @scrollBarSize;
    height: 100%;
    background-color: transparent;
    display: block;
  }

  & .-o-scrollbar {
    display: block;
  }

  /* 兼容IE */
  -ms-overflow-style: block;
  -ms-scroll-chaining: chained;
  -ms-content-zooming: zoom;
  -ms-scroll-rails: block;
  -ms-content-zoom-limit-min: 100%;
  -ms-content-zoom-limit-max: 500%;
  -ms-scroll-snap-type: proximity;
  -ms-scroll-snap-points-x: snapList(100%, 200%, 300%, 400%, 500%);

  /* 定义滚动条轨道 */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* 定义滑块 */
  &::-webkit-scrollbar-thumb {
    border-radius: @scrollBarSize;
    background-color: #c4c4c4;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: #b4b4b4;
    }

    &:active {
      background-color: #bbb;
    }
  }
}
</style>
